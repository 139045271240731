body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
input {
  border: none;
  outline: none;
}
:root {
  justify-content: center;
  width: 100%;
}

a {
  text-decoration: none;
  color: black;
}
h2 {
  font-weight: bold;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  width: 100%;
  margin-top: 0px;
  margin: auto;
  height: auto;
}
.slick-slider > div {
  display: grid !important;
  place-items: center !important;
}

@media (max-width: 1024px) {
  .slick-slide > div {
    height: auto;
  }
}
@media (max-width: 500px) {
  .slick-slide > div {
    width: 100%;
    margin-bottom: 50px;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #142658 !important;
  font-size: 50px !important;
}
@media (max-width: 300px) {
  .slick-prev:before,
  .slick-next:before {
    color: white !important;
    font-size: 0px !important;
  }
}
.slick-dots button {
  width: 0px !important;
  height: 0px !important;
  color: white !important;
}
