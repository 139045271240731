.mobileMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 9vh;
  right: 0;
  background-color: white;
  width: 100vw;
  height: 0vh;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  font-size: calc(21px + 1 * ((100vw - 320px) / 680));
}
.mobileMenu.active {
  height: 100vh;
}
.menuItems {
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: flex-start;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.menuItems.active {
  opacity: 1; /* zmieniono wartość przezroczystości na 1 */
  transform: translateY(0); /* zmieniono przesunięcie w osi Y na 0 */
}
.menuItems ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuItems li {
  margin: 10% 0;
}

.menuItems a {
  color: #333;
  text-decoration: none;
}
.callMe {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #142658;
  border-radius: 20px;
  padding: 6px 15px 6px 15px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}
.callMe:hover {
  transform: scale(1.05);
}
