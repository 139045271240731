.whyUs {
  background-color: transparent;
  padding: 0% 10%;
  margin-top: 12vh;
  color: #ffff;
}

.reasons {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}
.borderLine {
  width: 100%;
  height: 4px;
  content: "";
  position: relative;
  background-color: rgb(255, 255, 255);
}
.activeBorderLine {
  width: 30%;
  height: 5px;
  background: #142658;
  transition: all 1200ms;
}
.reason {
  flex-basis: 25%;
  text-align: center;
  margin-bottom: 35px;
}

.reasonIcon {
  font-size: calc(28px + 12 * ((100vw - 320px) / 400));
  transition: 0.3s ease-in-out 0s;
  cursor: pointer;
  margin-bottom: 5px;
}
.reasonIcon:hover {
  transform: scale(1.05);
}
.reasonTitle {
  font-size: calc(14px + 6 * ((100vw - 320px) / 900));
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
}

.reasonDescription {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: #ffff;
  margin-top: 5%;
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
  font-size: calc(10px + 1vh);
}
.reasonDescriptionIcon {
  display: flex;
  font-size: 1em;
  margin-right: 5%;
}
.reasonDescriptionText {
  display: flex;
}
@media (min-width: 1800px) {
  .reasonDescription {
    font-size: 1.5em;
  }
}

@media (max-width: 1200px) {
  .whyUs {
    margin-top: 8vh;
  }
  .reasonDescriptionText {
    display: none;
  }
}
@media (max-width: 600px) {
  .whyUs {
    margin-top: 6vh;
  }
  .reasonTitle {
    font-size: calc(5px + 1vh);
    font-weight: 700;
  }
  .reasonIcon {
    font-size: 2.2em;
  }
}
@media (max-width: 420px) {
  .reason {
    /* display: none; */
  }
}

@media (max-width: 320px) {
  .reasonTitle {
    font-size: calc(4px + 1vh);
  }
  .reasonIcon {
    font-size: 2.1em;
  }
}
