.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 9vh;
  max-height: 140px;
  box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.12),
    -13px -11px 1px rgba(0, 0, 0, 0.14), -16px -11px 1px rgb(0 0 0 / 20%);
}

.logo {
  display: flex;
  max-width: calc(120px + 2vw);
  width: auto;
  height: 100%;
  margin-left: 2%;
  max-height: 120px;
}
.navbarList {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-right: 35px;
  padding: 0;
  font-size: calc(3px + 1vw);
}
@media (max-width: 1200px) {
  .navbarList {
    font-size: calc(4px + 1vw);
  }
  .logo img {
    height: 8vh !important;
  }
  .header {
    height: 8vh;
  }
}
@media (max-width: 550px) {
  .logo {
    margin-left: 10px;
    padding-top: 0px;
  }
  .logo img {
    height: 7vh !important;
    max-width: 100px;
  }
  .header {
    height: 7vh;
  }
}
@media (max-width: 375px) {
  .logo img {
    max-width: 80px;
  }
  .header {
    height: 7vh;
  }
}
@media (max-width: 320px) {
  .logo img {
    max-width: 65px;
  }
}
.navbarItem {
  margin-right: 5vw;
  transition: 0.3s ease-in-out 0s;
}
.navbarItem:hover {
  transform: scale(1.05);
}

.callMe {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #142658;
  border-radius: 20px;
  padding: 6px 15px 6px 15px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}
.callMe:hover {
  transform: scale(1.05);
}
