.mapContainer {
  display: flex;
  justify-content: center;
  padding: 0 15%;
}
.mapImg {
  display: flex;
  max-height: 484px;
  justify-content: center;
  align-items: center;
}
.mapDescription {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1em;
  text-align: right;
  text-shadow: 3px 2px 6px rgba(207, 207, 207, 0.9);
  padding: 3%;
  flex-direction: column;
  color: black;
}
.sloganTitle {
  font-weight: 600;
  letter-spacing: 3px;
  color: black;
  text-align: right;
}
@media (min-width: 1600px) {
  .mapDescription {
    font-size: 1.4em;
  }
}
@media (max-width: 1024px) {
  .mapImg {
    max-width: 570px;
    max-height: 80%;
    margin-top: 5%;
  }
  .mapContainer {
    margin-bottom: 10%;
    flex-direction: column;
  }
  .mapDescription {
    font-size: 20px;
  }
}
@media (max-width: 570px) {
  .mapContainer {
    padding: 1%;
  }
  .mapImg {
    padding-top: 10%;
  }
  .mapImg img {
    max-width: 300px;
  }
  .mapDescription {
    font-size: 0.6rem;
    margin-top: 20px;
    padding: 0 30px;
  }
}
@media (max-width: 321px) {
  .mapDescription {
    font-size: calc(14px + 1 * ((100vw - 320px) / 680));
  }
}
