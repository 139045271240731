.offerSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 2%;
  padding-left: 2%;
  padding-bottom: 5%;
  padding-top: 2%;
  text-align: center;
  background: #ffff;
}
.offerSectionTitle {
  font-size: 1.5em;
  font-weight: bold;
}
.offerCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
  gap: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.offerCard {
  display: flex;
  justify-content: flex-end;
  box-shadow: 2px 5px 3px rgba(0, 0, 0, 0.12), 2px 4px 18px rgba(0, 0, 0, 0.14),
    0px 0px 20px rgba(0, 0, 0, 0.2);
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 85%;
  transition: box-shadow 0.5s ease;
  border-radius: 2px;
  height: 60vh;
  width: 100%;
  transition: 0.3s ease-in-out 0s;
  cursor: pointer;
  overflow: hidden;
}
.offerCard ul {
  padding-left: 5%;
}

.offerCard:hover {
  transform: scale(1.01);
  overflow: hidden;
}
.ContentOfferCard {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 8%;
  padding-left: 8%;
}
.offerTitle {
  display: flex;
  align-items: center;
  /* padding-bottom: 5%; */
  justify-content: center;
  font-size: 1.2em;
  width: 90%;
  height: 25%;
  padding-top: 10%;
  border-bottom: 1px solid white;
  border-radius: 3px;
}
.offerList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  height: 100%;
  width: 80%;
  padding-top: 10%;
  margin: 0;
  font-size: calc(12px + 1vh);
}
.offerListElem {
  margin: 0% 0% 15% 0%;
}
.btnReadmoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 30%;
  width: 90%;
  border-top: 1px solid white;
  border-radius: 3px;
}
.btnReadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  border-radius: 20px;
  padding: 4px 8px 4px 13px;
  text-shadow: none;
  background: #fff;
  color: #000;
  border: 2px solid white;
  transition: 0.3s ease-in-out 0s;
  font-weight: 600;
}
.iconReadmore {
  font-size: 19px;
  display: flex;
  margin-left: 5px;
}
.btnReadmore:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: white;
  background: transparent;
}

@media (min-width: 1800px) {
  .offerCards {
    gap: 30px;
  }
  .offerSection {
    padding-top: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
  .offerCard {
    height: 52vh;
  }
  .offerList {
    font-size: 1.2em;
  }
  .btnReadmore {
    font-size: 0.75em;
  }
}
@media (min-width: 500px) {
  .offerListElem {
    margin: 0% 0% 10% 0%;
  }
}

@media (max-width: 500px) {
  .offerSection {
    padding-left: 10%;
    padding-right: 10%;
  }
  .offerCard {
    height: 62vh;
    min-height: 425px;
    max-height: 500px;
  }
  .offerTitle {
    padding-top: 5%;
  }
  .offerList {
    font-size: 1em;
  }
  .btnReadmoreContainer {
    height: 30%;
  }
  .btnReadmore {
    font-size: 55%;
    padding: 2px 6px 2px 10px;
  }
  .offerListElem {
    margin: 0% 0% 12% 0%;
  }
}
@media (max-width: 355px) {
  .offerCard {
    height: 66vh;
    max-height: 480px;
  }
  .offerListElem {
    margin: 0% 0% 13% 0%;
  }
}
