.banner {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: flex-start;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  height: 152vh;
}
.banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 153vh;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  pointer-events: none;
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 50%,
    white 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 50%,
    white 100%
  );
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
}

.bannerContent {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 78vh;
  max-height: 78vh;
  min-height: 78vh;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
}
.bannerTitle {
  display: flex;
  color: #ffffff;
  font-size: calc(12px + 8 * ((100vw - 320px) / 650));
  font-weight: bold;
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
  margin-bottom: 10px;
}
.bannerSubTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
  font-size: calc(8px + 6 * ((100vw - 320px) / 650));

  letter-spacing: 8px;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.subTitleElemFirst {
  text-align: end;
}
.subTitleElemSecound {
  text-align: start;
}
.subTitleIcon {
  display: flex;
  font-size: 1em;
  margin: 0px 25px;
}
.btnReadmore {
  display: flex;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  border-radius: 20px;
  padding: 0.5% 8px 0.5% 13px;
  text-shadow: none;
  background: transparent;
  border: 2px solid white;
  color: white;
  transition: 0.3s ease-in-out 0s;
  font-weight: 600;
}
.iconReadmore {
  font-size: 19px;
  display: flex;
  margin-left: 5px;
}
.btnReadmore:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: #142658;
  color: #fff;
  border: 2px solid #142658;
}
@media (min-width: 1800px) {
  .btnReadmore {
    font-size: 0.9em;
  }
}

@media (min-width: 1200px) and (max-width: 1800px) {
  .banner {
    height: 137vh;
  }
  .banner::after {
    height: 140vh;
  }
  .bannerContent {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }
}

@media (max-width: 1200px) {
  .banner {
    height: 130vh;
    background-attachment: scroll !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 75%;
  }
  .bannerContent {
    height: 50vh;
    max-height: 50vh;
    min-height: 50vh;
    padding-top: 20%;
  }
  .bannerTitle {
    /* font-size: 2em; */
  }
  .bannerSubTitle {
    /* font-size: 1em; */
    margin-left: 10%;
    letter-spacing: 6px;
  }
  .subTitleIcon {
    margin: auto;
    margin-right: 6px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .banner {
    height: 120vh;
    justify-content: center;
  }
  .banner::after {
    height: 121vh;
  }
  .bannerContent {
    height: 42vh;
    max-height: 42vh;
    min-height: 42vh;
    padding-top: 30%;
  }
  .titleContainer {
    margin: 0;
  }
  .subTitleIcon {
    margin-right: 16px;
    margin-left: 12px;
  }
  .bannerTitle {
    /* font-size: 1.4em; */
    margin-bottom: 30px;
  }
  .bannerSubTitle {
    /* font-size: 0.9em; */
    margin-left: 12%;
    margin-right: 1%;
  }
  .btnReadmore {
    font-size: 0.6em;
  }
}

@media (max-width: 600px) {
  .banner {
    height: 115vh;
    justify-content: center;
  }
  .banner::after {
    height: 116vh;
  }
  .bannerContent {
    height: 42vh;
    max-height: 42vh;
    min-height: 42vh;
    padding-top: 50%;
  }
  .titleContainer {
    margin: 5% 0;
  }

  .bannerTitle {
    /* font-size: 1.5em; */
    margin-bottom: 30px;
  }
  .bannerSubTitle {
    /* font-size: 0.8em; */
    margin-left: 8%;
  }
  .btnReadmore {
    font-size: 0.6em;
  }
  .subTitleIcon {
    margin-right: 16px;
    margin-left: 12px;
  }
}
@media (max-width: 480px) {
  .bannerTitle {
    font-size: calc(18px + 8 * ((100vw - 320px) / 650));
  }

  .bannerSubTitle {
    font-size: calc(11px + 8 * ((100vw - 320px) / 650));

    margin-left: 10%;
  }
}
@media (max-width: 420px) {
  .banner {
    height: 100vh;
  }
  .banner::after {
    height: 101vh;
  }
  .bannerSubTitle {
    margin-left: 9%;
  }
}
@media (max-width: 420px) {
  .bannerSubTitle {
    margin-left: 9%;
  }
}
@media (max-width: 370px) {
  .bannerSubTitle {
    margin-left: 4%;
  }
}
@media (max-width: 330px) {
  .bannerSubTitle {
    margin-left: 2%;
  }
}
@media (max-width: 321px) {
  .bannerContent {
    height: 45vh;
    max-height: 45vh;
    min-height: 45vh;
  }
  .bannerTitle {
    font-size: calc(17px + 8 * ((100vw - 320px) / 650));
  }
  .bannerSubTitle {
    font-size: calc(10px + 2 * ((100vw - 320px) / 650));
    margin-left: 0%;
    width: 50%;
  }
  .subTitleIcon {
    margin-right: 5px;
    margin-left: 5px;
  }
}
