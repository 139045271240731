.App {
  text-align: center;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}
input {
  border: none;
  outline: none;
}
:root {
  justify-content: center;
  width: 100%;
}

a {
  text-decoration: none;
  color: black;
}
h2 {
  font-weight: bold;
}
