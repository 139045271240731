.sloganContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100vw;
  height: 35vh;
  display: flex;
  justify-content: center;
}
.sloganContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: calc(1px + 2vw);
  color: white;
  padding-left: 50vw;
  padding-right: 3vw;
  font-weight: bold;
  text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  background-color: rgba(8, 5, 48, 0.3);
}
@media (min-width: 844px) {
  .sloganContainer {
    background-attachment: fixed;
  }
}
@media (max-width: 844px) {
  .sloganContainer {
    height: 22vh;
  }
  .sloganContent {
    padding-left: 30vw;
    font-size: 14px;
  }
}
